/** Fonts **/
$base-font-size: 18;
$base-font-size-large: 24;
$base-line-height: 26;

$body-color:#000;

$page-section-padding: 50px 24px 24px 24px;
$hr-color: #000000;

$abellio-red: #CE132D;
$abellio-dark-grey: #6E6E6E;
$abellio-gray: #6B717A;

$carousel-border-color: #979797;

$sub-navigation-background: rgba(#D8D8D8, 0.6);

$button-background: #C71710;

$menu-svg-color: #9D9D9C;

$scrill-top-button-color: #C6C6C6;

$lighter-grey: #E8E8E8;

$light-grey: #F5F5F5;


$dark-gray: #B2B2B2;


$box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.03);
