
                        @import "@/assets/scss/_variables.scss";
                        @import "@/assets/scss/_mixins.scss";
                    

.backend-edit-button {
   position: fixed;
   background: $abellio-red;
   bottom: 20px;
   right: 20px;
   padding: 10px 25px;
   z-index: 100;
   color: #FFF;
   font-weight: bold;
   -webkit-box-shadow: 0 0 40px -14px rgba(0, 0, 0, 0.75);
   -moz-box-shadow: 0 0 40px -14px rgba(0, 0, 0, 0.75);
   box-shadow: 0 0 40px -14px rgba(0, 0, 0, 0.75);
}
